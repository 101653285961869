import { useEffect } from "react";
import { NextRouter, useRouter } from "next/router";

export default function Error404() {
  const router: NextRouter = useRouter();

  useEffect(() => {
    router.replace('/');
}, [router]);

  return null
}